import { Injectable } from '@angular/core';

import * as msRest from 'ms-rest-js';

import { User } from 'oidc-client';

import { AuthenticationService } from '@methylium/authentication';

import { SmartBuildingApi } from '@smartbuilding/sdk';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {

    public client: SmartBuildingApi;

    private get apiUrl(): string {
        return environment.smartbuilding.api;
    }

    constructor(
        private readonly authenticationService: AuthenticationService) {

        const credentials = new msRest.TokenCredentials(' ');
        this.client = new SmartBuildingApi(credentials, this.apiUrl);

        this.authenticationService.user$.subscribe(user => this.updateToken(user));
    }

    private updateToken(user: User): void {
        let token = ' ';

        if (user) {
            token = user.access_token;
        }

        const credentials = new msRest.TokenCredentials(token);
        this.client = new SmartBuildingApi(credentials, this.apiUrl);
    }
}
