import { Component } from '@angular/core';

import { IFloatingFilter, SerializedTextFilter } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';

import { TextDropdownFloatingFilterChange } from 'src/app/models/text-dropdown-floating-filter-change.model';
import { TextDropdownFloatingFilterParams } from 'src/app/models/text-dropdown-floating-filter-params.model';

@Component({
  selector: 'app-text-dropdown-filter',
  templateUrl: './text-dropdown-filter.component.html',
  styleUrls: ['./text-dropdown-filter.component.scss']
})
export class TextDropdownFilterComponent implements
  IFloatingFilter<SerializedTextFilter, TextDropdownFloatingFilterChange, TextDropdownFloatingFilterParams>,
  AgFrameworkComponent<TextDropdownFloatingFilterParams> {
  public params: TextDropdownFloatingFilterParams;
  public currentValue: string;

  agInit(params: TextDropdownFloatingFilterParams): void {
    this.params = params;
  }

  valueChanged(value: string): void {
    this.currentValue = value;
    this.params.onFloatingFilterChanged({ model: this.buildModel() });
  }

  onParentModelChanged(): void {
  }

  private buildModel(): SerializedTextFilter {
    if (this.currentValue === '') {
      return null;
    }
    return {
      filterType: 'text',
      type: 'equals',
      filter: this.currentValue,
    };
  }
}
