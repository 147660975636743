import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private readonly menuService: MenuService) { }

  public ngOnInit(): void {
  }

  public onToggleStateMenu(): void {
    const state: boolean = !this.menuService.getToggleState();
    this.menuService.setToggleState(state);
  }
}
