import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-floor-selector',
  templateUrl: './floor-selector.component.html',
  styleUrls: ['./floor-selector.component.scss']
})
export class FloorSelectorComponent implements OnInit, OnChanges {

  @Input()
  public floors: string[];

  @Output()
  public floorChange: EventEmitter<string>;

  public selectedFloor: string;

  constructor() {
    this.floorChange = new EventEmitter();
  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {

    const floors: string[] = changes.floors.currentValue;

    if (floors && floors.length > 0) {
      this.floors = this.floors.sort((a, b) => +b - +a);
    }
  }

  public onFloorSelected(floor: string): void {
    this.floorChange.emit(floor);
    this.selectedFloor = floor;
  }
}
