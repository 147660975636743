import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-building-selector',
  templateUrl: './building-selector.component.html',
  styleUrls: ['./building-selector.component.scss']
})
export class BuildingSelectorComponent implements OnInit {

  public selectedBuilding: string;

  constructor() {
    this.selectedBuilding = environment.building;
  }

  public ngOnInit(): void {
  }
}
