import { MeasurementType } from '../models/measurement-type.model';
import { AxisConfiguration } from '../models/axis-configuration.model';
import { axisConfigurations } from '../../environments/environment';

export function getAxisConfig(measurementType: MeasurementType): AxisConfiguration {
    switch (measurementType) {
        case MeasurementType.Temperature: {
            return axisConfigurations.temperature;
        }
        case MeasurementType.LightIntensity: {
            return axisConfigurations.lightIntensity;
        }
        case MeasurementType.Movement: {
            return axisConfigurations.movement;
        }
        case MeasurementType.CO2: {
            return axisConfigurations.CO2;
        }
        case MeasurementType.Humidity: {
            return axisConfigurations.humidity;
        }
        case MeasurementType.NoiseLevel: {
            return axisConfigurations.noiseLevel;
        }
    }
}
