import { Injectable } from '@angular/core';

import { BehaviorSubject, from, Observable, of } from 'rxjs';

import { ApiService } from '../services/api.service';
import { BuildingsDictionary } from '../helpers/buildings.dictionary';
import { tap, map } from 'rxjs/operators';
import { nodeFilter } from '../../environments/environment';

@Injectable()
export class BuildingsStore {

    private readonly _buildings$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    public readonly buildings$: Observable<string[]> = this._buildings$.asObservable();

    private buildingsDictionary: BuildingsDictionary;

    constructor(
        private readonly apiService: ApiService) {
        this.buildingsDictionary = new BuildingsDictionary();
    }

    public getBuildings(): Observable<string[]> {

        const oldBuildings = this._buildings$.getValue();

        if (oldBuildings.length <= 0) {
            from(this.apiService.client.getBuildings()).
                pipe(map(buildings => buildings.filter(b => !nodeFilter.building || b === nodeFilter.building)))
                .subscribe((buildings: string[]) => this._buildings$.next(buildings));
        }

        return this.buildings$;
    }

    public getFloors(building: string): Observable<number[]> {

        const cachedFloors: number[] = this.buildingsDictionary.get(building);

        if (cachedFloors && cachedFloors.length > 0) {
            return of<number[]>(cachedFloors);
        }

        return from(this.apiService.client.getFloors(building))
            .pipe(
                map(floors => floors.filter(f => f !== null)),
                map(floors => floors.filter(floor => !nodeFilter.floors || nodeFilter.floors.includes(floor))),
                tap((t: number[]) =>
                    this.buildingsDictionary.set(building, t)));
    }
}
