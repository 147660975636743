import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';

import * as moment from 'moment';

import { NodesService } from '../../services/nodes.service';
import { environment } from '../../../environments/environment';
import { MeasurementsService } from '../../services/measurements.service';
import { PlotSettings } from '../../models/plot.model';
import { AxisConfiguration } from '../../models/axis-configuration.model';
import { Measurement } from '../../models/measurement.model';
import { createFloorPlanPlotSettings } from '../../factories/floor-plan-plot-settings.factory';
import { MeasurementType } from '../../models/measurement-type.model';
import { getAxisConfig } from '../../helpers/chart.helper';
import { ODataOptions } from '../../models/odata.model';
import { isNoiseLevelMeasurementType } from '../../helpers/measurements.helper';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Node = SmartBuildingApiModels.Node;

@Component({
  selector: 'app-floors',
  templateUrl: './floors.view.html',
  styleUrls: ['./floors.view.scss']
})
export class FloorsViewComponent implements OnInit, OnDestroy {

  @ViewChild('floorPlanChartContainerDiv')
  private elFloorPlanChartContainerDiv: ElementRef;

  public nodes$: Observable<Node[]>;
  public floorPlanPlotSettings$: Observable<PlotSettings>;

  private _measurementTypeSelection$: BehaviorSubject<MeasurementType | 'Node'> = new BehaviorSubject('Node') as BehaviorSubject<'Node'>;
  public measurementTypeSelection$: Observable<MeasurementType | 'Node'> = this._measurementTypeSelection$.asObservable();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly nodesService: NodesService,
    private readonly measurementsService: MeasurementsService) { }

  public ngOnInit(): void {

    this.nodes$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('floor')),
      switchMap((floor: string) => this.nodesService.getNodes(environment.building, +floor)),
      map(nodes => nodes.filter((n: Node) => n.y < 15)),
      map(nodes => {
        nodes.push({
          id: 90001,
          locationId: 90001,
          name: 'Node',
          building: 'PRIVA',
          floor: 1,
          x: 0,
          y: 0,
          z: 1.0
        });
        nodes.push({
          id: 90002,
          locationId: 90002,
          name: 'Node',
          building: 'PRIVA',
          floor: 1,
          x: 0,
          y: 14.98,
          z: 1.0
        });
        nodes.push({
          id: 90002,
          locationId: 90002,
          name: 'Node',
          building: 'PRIVA',
          floor: 1,
          x: 36,
          y: 14.98,
          z: 1.0
        });
        nodes.push({
          id: 90002,
          locationId: 90002,
          name: 'Node',
          building: 'PRIVA',
          floor: 1,
          x: 36,
          y: 0,
          z: 1.0
        });
        return nodes;
      }));

    this.floorPlanPlotSettings$ = combineLatest(this.nodes$, this.measurementTypeSelection$).pipe(
      filter(([n]: any) => n.length > 0),
      switchMap(([n, mts]: any) => {

        const dateTo: Date = new Date();
        const dateFrom: Date = moment().subtract(15, 'minutes').toDate();
        
        const odataOptions: ODataOptions = {
          filter:
            `MeasureTime gt ${moment.utc(dateFrom).format()} AND MeasureTime lt ${moment.utc(dateTo).format()} AND Node/Floor eq ${n[0].floor}`
        };

        return this.measurementsService.getMeasurements(mts, odataOptions).pipe(
          map((m: Measurement[]) => ({ nodes: n, measurements: m, config: this.getAxisConfigurations(mts), measurementType: mts })));
      }),
      map((nm: any) => {
        return ({
          nodes: nm.nodes,
          measurementValues: this.setMeasurementValues(nm),
          config: nm.config,
          measurementType: nm.measurementType
        });
      }),
      map((mv: any) => {

        const xCoordinates: number[] = mv.nodes.map((node: Node) => node.x);
        const yCoordinates: number[] = mv.nodes.map((node: Node) => node.y);
        const zCoordinates: number[] = mv.nodes.map((node: Node) => node.z);

        const x: number[] = xCoordinates.filter(
          (element, position, array) => array.indexOf(element) === position).sort((a, b) => a - b);
        const y: number[] = yCoordinates.filter(
          (element, position, array) => array.indexOf(element) === position).sort((a, b) => a - b);

        const zArray: number[][] = this.getZArray(x, y);

        return createFloorPlanPlotSettings(
          {
            xCoordinates: xCoordinates,
            yCoordinates: yCoordinates,
            zCoordinates: zCoordinates
          },
          {
            x: x,
            y: y,
            z: zArray,
          },
          mv.measurementValues,
          mv.config,
          {
            width: this.elFloorPlanChartContainerDiv.nativeElement.offsetWidth,
            height: this.elFloorPlanChartContainerDiv.nativeElement.offsetHeight
          },
          mv.measurementType
        );
      }));
  }

  private getZArray(x, y): number[][] {

    const zArray: number[][] = [];

    for (let i = 0; i < y.length; i++) {
      zArray.push([]);
      for (let j = 0; j < x.length; j++) {
        zArray[i].push(null);
      }
    }

    return zArray;
  }

  private getAxisConfigurations(type: MeasurementType | 'Node'): AxisConfiguration {

    if (type === 'Node') {
      return null;
    }

    return getAxisConfig(type);
  }

  public onMeasurementTypeSelected(measurementType: MeasurementType | 'Node'): void {
    this._measurementTypeSelection$.next(measurementType);
  }

  private setMeasurementValues(nodeMeasurements: any): number[] {
    const measurementValues: number[] = [];

    if (nodeMeasurements.measurements.length === 0) {
      return measurementValues;
    }

    for (let i = 0; i < nodeMeasurements.nodes.length; i++) {
      const measurementsByNodeId = nodeMeasurements.measurements
        .filter(measurement => measurement.nodeId === nodeMeasurements.nodes[i].id)
        .map(measurement => {
          if (isNoiseLevelMeasurementType(measurement)) {
            return measurement.averageValue;
          }
          return measurement.value;
        });

      let average = null;

      if (measurementsByNodeId.length > 0) {
        const summedValues = measurementsByNodeId.reduce((sum, value) => sum + value);
        average = summedValues / measurementsByNodeId.length;
      }
      measurementValues.push(average);
    }

    return measurementValues;
  }

  public navigateToNode(node: Node): void {
    this.router.navigate(['nodes', node.id]);
  }

  public ngOnDestroy(): void {
  }
}
