import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap, shareReplay, tap, filter, debounceTime } from 'rxjs/operators';

import { Datum } from 'plotly.js';

import * as moment from 'moment';

import { MeasurementsFilter } from '../../models/measurements-filter.model';
import { PlotSettings } from '../../models/plot.model';
import { NodesService } from '../../services/nodes.service';
import { getFromDate, getToDate } from '../../helpers/date.helper';
import { MeasurementsService } from '../../services/measurements.service';
import { axisConfigurations } from '../../../environments/environment';
import { DateRange } from '../../models/date-range.model';
import { createMeasurementsPlotSettings } from '../../factories/measurements-plot-settings.factory';
import { MeasurementType } from '../../models/measurement-type.model';
import { ODataOptions } from '../../models/odata.model';

import { MeasurementsEventsService } from '../../services/measurements-events.service';
import { CO2MeasurementLoadStarted } from '../../models/events/co2-measurement-load-started.model';
import { TemperatureMeasurementLoadStarted } from '../../models/events/temperature-measurement-load-started.model';
import { HumidityMeasurementLoadStarted } from '../../models/events/humidity-measurement-load-started.model';
import { LightIntensityMeasurementLoadStarted } from '../../models/events/light-intensity-measurement-load-started.model';
import { MovementMeasurementLoadStarted } from '../../models/events/movement-measurement-load-started.model';
import { NoiseLevelMeasurementLoadStarted } from '../../models/events/noise-level-measurement-load-started.model';
import { TemperatureMeasurementLoadCompleted } from '../../models/events/temperature-measurement-load-completed.model';
import { HumidityMeasurementLoadCompleted } from '../../models/events/humidity-measurement-load-completed.model';
import { CO2MeasurementLoadCompleted } from '../../models/events/co2-measurement-load-completed.model';
import { LightIntensityMeasurementLoadCompleted } from '../../models/events/light-intensity-measurement-load-completed.model';
import { NoiseLevelMeasurementLoadCompleted } from '../../models/events/noise-level-measurement-load-completed.model';
import { MovementMeasurementLoadCompleted } from '../../models/events/movement-measurement-load-completed.model';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Temperature = SmartBuildingApiModels.Temperature;
import Humidity = SmartBuildingApiModels.Humidity;
import CO2 = SmartBuildingApiModels.CO2;
import LightIntensity = SmartBuildingApiModels.LightIntensity;
import Movement = SmartBuildingApiModels.Movement;
import NoiseLevel = SmartBuildingApiModels.NoiseLevel;
import Node = SmartBuildingApiModels.Node;

@Component({
  selector: 'app-node-details',
  templateUrl: './node-details.view.html',
  styleUrls: ['./node-details.view.scss']
})
export class NodeDetailsViewComponent implements OnInit, OnDestroy {

  public measurementType: (typeof MeasurementType) = MeasurementType;

  public node$: Observable<Node>;

  public readonly selectedDate$: Subject<Date>;
  public readonly selectedDateRange$: Subject<DateRange>;

  public temperaturePlotSettings$: Observable<PlotSettings>;
  public humidityPlotSettings$: Observable<PlotSettings>;
  public co2PlotSettings$: Observable<PlotSettings>;
  public lightIntensityPlotSettings$: Observable<PlotSettings>;
  public movementPlotSettings$: Observable<PlotSettings>;
  public noiseLevelPlotSettings$: Observable<PlotSettings>;

  public isTemperatureMeasurementsLoading$: Observable<boolean>;
  public isHumidityMeasurementsLoading$: Observable<boolean>;
  public isCo2MeasurementsLoading$: Observable<boolean>;
  public isLightIntensityMeasurementsLoading$: Observable<boolean>;
  public isMovementMeasurementsLoading$: Observable<boolean>;
  public isNoiseLevelMeasurementsLoading$: Observable<boolean>;

  private _rangeSlider$: Subject<Datum[]> = new Subject();
  public rangeSlider$: Observable<Datum[]> = this._rangeSlider$.asObservable().pipe(debounceTime(250));

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly nodesService: NodesService,
    private readonly measurementsService: MeasurementsService,
    private readonly measurementsEventsService: MeasurementsEventsService,
  ) {
    const dateParam = this.getQueryParam('date');

    const date: Date = dateParam
      ? new Date(dateParam)
      : new Date();

    this.selectedDate$ = new BehaviorSubject(date);

    const rangeParam = this.getQueryParam('range');

    const range: DateRange = rangeParam
      ? DateRange[rangeParam]
      : DateRange.Day;

    this.selectedDateRange$ = new BehaviorSubject(range);
  }

  ngOnInit(): void {

    const nodeId = +this.getParam('id');
    this.node$ = this.nodesService.getNodeById(nodeId);

    const selectors = combineLatest(this.selectedDate$, this.selectedDateRange$).pipe(
      tap(([cd, cr]) => this.changeQueryParams({ date: cd.toISOString(), range: cr })));

    const measurementsFilter: Observable<MeasurementsFilter> =
      combineLatest(this.node$, selectors)
        .pipe(
          map(([node, [curr, range]]) => {

            const dateFrom = getFromDate(curr, range);
            const dateTo = getToDate(curr, range);

            const mf = this.measurementsService.getFilter(
              dateTo,
              dateFrom,
              node.floor,
              node.id);

            return mf;
          }),
          shareReplay(1));

    this.temperaturePlotSettings$ =
      measurementsFilter.pipe(
        switchMap((mf: MeasurementsFilter) => {

          const odataOptions: ODataOptions = {
            filter:
              `MeasureTime gt ${moment.utc(mf.from).format()} AND MeasureTime lt ${moment.utc(mf.to).format()} AND NodeId eq ${mf.nodeId}`
          };

          return this.measurementsService.getTemperatureMeasurements(odataOptions).pipe(
            map((ms: Temperature[]) => ({ filter: mf, measurements: ms })));
        }),
        map(fms => createMeasurementsPlotSettings(fms.measurements, [fms.filter.from, fms.filter.to], axisConfigurations.temperature)));

    this.isTemperatureMeasurementsLoading$ =
      this.measurementsEventsService.measurementEvents$.pipe(
        filter((x) => x instanceof TemperatureMeasurementLoadStarted ||
          x instanceof TemperatureMeasurementLoadCompleted),
        map((x) => x instanceof TemperatureMeasurementLoadStarted));

    this.humidityPlotSettings$ =
      measurementsFilter.pipe(
        switchMap((mf: MeasurementsFilter) => {

          const odataOptions: ODataOptions = {
            filter:
              `MeasureTime gt ${moment.utc(mf.from).format()} AND MeasureTime lt ${moment.utc(mf.to).format()} AND NodeId eq ${mf.nodeId}`
          };

          return this.measurementsService.getHumidityMeasurements(odataOptions).pipe(
            map((ms: Humidity[]) => ({ filter: mf, measurements: ms })));
        }),
        map(fms => createMeasurementsPlotSettings(fms.measurements, [fms.filter.from, fms.filter.to], axisConfigurations.humidity)));

    this.isHumidityMeasurementsLoading$ =
      this.measurementsEventsService.measurementEvents$.pipe(
        filter((x) => x instanceof HumidityMeasurementLoadStarted ||
          x instanceof HumidityMeasurementLoadCompleted),
        map((x) => x instanceof HumidityMeasurementLoadStarted));

    this.co2PlotSettings$ =
      measurementsFilter.pipe(
        switchMap((mf: MeasurementsFilter) => {

          const odataOptions: ODataOptions = {
            filter:
              `MeasureTime gt ${moment.utc(mf.from).format()} AND MeasureTime lt ${moment.utc(mf.to).format()} AND NodeId eq ${mf.nodeId}`
          };

          return this.measurementsService.getCo2Measurements(odataOptions).pipe(
            map((ms: CO2[]) => ({ filter: mf, measurements: ms })));
        }),
        map(fms => createMeasurementsPlotSettings(fms.measurements, [fms.filter.from, fms.filter.to], axisConfigurations.CO2)));

    this.isCo2MeasurementsLoading$ =
      this.measurementsEventsService.measurementEvents$.pipe(
        filter((x) => x instanceof CO2MeasurementLoadStarted ||
          x instanceof CO2MeasurementLoadCompleted),
        map((x) => x instanceof CO2MeasurementLoadStarted));

    this.lightIntensityPlotSettings$ =
      measurementsFilter.pipe(
        switchMap((mf: MeasurementsFilter) => {

          const odataOptions: ODataOptions = {
            filter:
              `MeasureTime gt ${moment.utc(mf.from).format()} AND MeasureTime lt ${moment.utc(mf.to).format()} AND NodeId eq ${mf.nodeId}`
          };

          return this.measurementsService.getLightIntensityMeasurements(odataOptions).pipe(
            map((ms: LightIntensity[]) => ({ filter: mf, measurements: ms })));
        }),
        map(fms => createMeasurementsPlotSettings(fms.measurements, [fms.filter.from, fms.filter.to], axisConfigurations.lightIntensity)));

    this.isLightIntensityMeasurementsLoading$ =
      this.measurementsEventsService.measurementEvents$.pipe(
        filter((x) => x instanceof LightIntensityMeasurementLoadStarted ||
          x instanceof LightIntensityMeasurementLoadCompleted),
        map((x) => x instanceof LightIntensityMeasurementLoadStarted));

    this.movementPlotSettings$ =
      measurementsFilter.pipe(
        switchMap((mf: MeasurementsFilter) => {

          const odataOptions: ODataOptions = {
            filter:
              `MeasureTime gt ${moment.utc(mf.from).format()} AND MeasureTime lt ${moment.utc(mf.to).format()} AND NodeId eq ${mf.nodeId}`
          };

          return this.measurementsService.getMovementMeasurements(odataOptions).pipe(
            map((ms: Movement[]) => ({ filter: mf, measurements: ms })));
        }),
        map(fms => createMeasurementsPlotSettings(fms.measurements, [fms.filter.from, fms.filter.to], axisConfigurations.movement)));

    this.isMovementMeasurementsLoading$ =
      this.measurementsEventsService.measurementEvents$.pipe(
        filter((x) => x instanceof MovementMeasurementLoadStarted ||
          x instanceof MovementMeasurementLoadCompleted),
        map((x) => x instanceof MovementMeasurementLoadStarted));

    this.noiseLevelPlotSettings$ =
      measurementsFilter.pipe(
        switchMap((mf: MeasurementsFilter) => {

          const odataOptions: ODataOptions = {
            filter:
              `MeasureTime gt ${moment.utc(mf.from).format()} AND MeasureTime lt ${moment.utc(mf.to).format()} AND NodeId eq ${mf.nodeId}`
          };

          return this.measurementsService.getNoiseLevelMeasurements(odataOptions).pipe(
            map((ms: NoiseLevel[]) => ({ filter: mf, measurements: ms })));
        }),
        map(fms => createMeasurementsPlotSettings(fms.measurements, [fms.filter.from, fms.filter.to], axisConfigurations.noiseLevel)));

    this.isNoiseLevelMeasurementsLoading$ =
      this.measurementsEventsService.measurementEvents$.pipe(
        filter((x) => x instanceof NoiseLevelMeasurementLoadStarted ||
          x instanceof NoiseLevelMeasurementLoadCompleted),
        map((x) => x instanceof NoiseLevelMeasurementLoadStarted));
  }

  public onDateChanged(date: Date): void {

    const now = new Date();

    const dateWithTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds());

    this.selectedDate$.next(dateWithTime);
  }

  public onDateRangeChanged(range: DateRange): void {
    this.selectedDateRange$.next(range);
  }

  public rangeSliderChanged(params: any) {
    this._rangeSlider$.next(params);
  }

  private changeQueryParams(params: any) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private getQueryParam(name: string): string {
    return this.route.snapshot.queryParamMap.get(name);
  }

  private getParam(name: string): string {
    return this.route.snapshot.paramMap.get(name);
  }

  public ngOnDestroy(): void {
  }
}
