import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-router-link-renderer',
  templateUrl: './router-link-renderer.component.html',
  styleUrls: ['./router-link-renderer.component.scss']
})
export class RouterLinkRendererComponent implements AgRendererComponent {
  public params: any;

  constructor() { }

  agInit(params: any) {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}
