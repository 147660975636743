import { Injectable } from '@angular/core';

import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';

import { NodesDictionary } from '../helpers/nodes.dictionary';
import { ApiService } from '../services/api.service';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Node = SmartBuildingApiModels.Node;
import { environment, nodeFilter } from '../../environments/environment';

@Injectable()
export class NodesStore {

    private readonly _nodes$: BehaviorSubject<Node[]> = new BehaviorSubject([]);
    public readonly nodes$: Observable<Node[]> = this._nodes$.asObservable();

    private readonly nodesDictionary: NodesDictionary;

    constructor(
        private readonly apiService: ApiService) {

        this.nodesDictionary = new NodesDictionary();

        this.load();
    }

    private load(): void {
        from(this.apiService.client.getNodes())
            .pipe(map(nodes => this.filterNodes(nodes)))
            .subscribe((n: Node[]) => this._nodes$.next(n));
    }

    public getNodes(building?: string, floor?: number): Observable<Node[]> {

        if (!building && !floor) {
            return this.nodes$;
        }

        const nodes: Node[] = this.nodesDictionary.get([building, floor]);

        if (nodes && nodes.length > 0) {
            return of<Node[]>(nodes);
        }

        return this.nodes$.pipe(
            filter((ns: Node[]) => ns.length > 0),
            map((ns: Node[]) =>
                ns.filter((n: Node) => n.floor === +floor)),
            tap((ns: Node[]) =>
                this.nodesDictionary.set([building, floor], ns)));
    }

    public getNodeById(id: number): Observable<Node> {

        const nodes = this._nodes$.getValue();

        const nodeExists = nodes.find((node: Node) => node.id === id);

        if (nodeExists) {
            return of<Node>(nodeExists);
        }

        return from(this.apiService.client.getNodeById(id));
    }

    private filterNodes(nodes: Node[]): Node[] {

        // Filter nodes based on filter set in environment config
        return nodes.filter(n =>
            (!nodeFilter.building || n.building === nodeFilter.building) &&
            (!nodeFilter.floors || nodeFilter.floors.includes(n.floor)));
    }
}
