import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { DateRange } from '../../models/date-range.model';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss']
})
export class DateRangeSelectorComponent implements OnInit {

  @Output()
  public dateRangeChange: EventEmitter<string>;

  @Input()
  public selectedDateRange: string;

  public readonly dataRangeSelectors: string[];

  constructor() {
    this.dateRangeChange = new EventEmitter();
    this.dataRangeSelectors = Object.keys(DateRange);
  }

  public onDateRangeChanged(range: string): void {
    this.dateRangeChange.emit(range);
  }

  ngOnInit() {
  }
}
