import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { MeasurementEventLoadCompleted, MeasurementEventLoadStarted } from '../models/measurement-load-event.model';

@Injectable({
  providedIn: 'root'
})
export class MeasurementsEventsService {

  private readonly _measurementEvents$: ReplaySubject<MeasurementEventLoadStarted | MeasurementEventLoadCompleted> =
    new ReplaySubject<MeasurementEventLoadStarted | MeasurementEventLoadCompleted>(1);

  public readonly measurementEvents$: Observable<MeasurementEventLoadStarted | MeasurementEventLoadCompleted> =
    this._measurementEvents$.asObservable();

  constructor() { }

  public publish(data: MeasurementEventLoadStarted | MeasurementEventLoadCompleted): void {
    this._measurementEvents$.next(data);
  }
}
