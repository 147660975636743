import { Layout, PlotData, Config, Datum } from 'plotly.js';

import { isNoiseLevelMeasurementType } from '../helpers/measurements.helper';
import { AxisConfiguration } from '../models/axis-configuration.model';
import { Measurement } from '../models/measurement.model';
import { PlotSettings } from '../models/plot.model';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Temperature = SmartBuildingApiModels.Temperature;
import Humidity = SmartBuildingApiModels.Humidity;
import CO2 = SmartBuildingApiModels.CO2;
import LightIntensity = SmartBuildingApiModels.LightIntensity;
import Movement = SmartBuildingApiModels.Movement;
import NoiseLevel = SmartBuildingApiModels.NoiseLevel;

export function createMeasurementsPlotSettings(
  measurements: Measurement[],
  range: Date[],
  axisConfiguration: AxisConfiguration): PlotSettings {
  return new MeasurementsPlotSettingsFactory(measurements, range, axisConfiguration).create();
}

export function createMeasurementsPlotLayout(
    range: Datum[],
    axisConfiguration: AxisConfiguration): Partial<Layout> {
    return new MeasurementsPlotSettingsFactory(undefined, range, axisConfiguration).createPlotLayout();
}

class MeasurementsPlotSettingsFactory {

    constructor(
        private readonly measurements: Measurement[],
        private readonly range: Datum[],
        private readonly axisConfiguration: AxisConfiguration) {
    }

  public create(): PlotSettings {
    let measureTime = this.measurements.map((m: Measurement) => m.measureTime);
    measureTime = measureTime.sort((a: Date, b: Date) => a.getTime() - b.getTime());

    const xCoordinates = measureTime;
    const yCoordinates = this.measurements.map((m: Measurement) => {
      if (isNoiseLevelMeasurementType(m)) {
        return (<NoiseLevel>m).averageValue;
      }
      return (<Temperature | Humidity | CO2 | LightIntensity | Movement>m).value;
    });

        const plotData: Partial<PlotData> = {
            x: xCoordinates,
            y: yCoordinates,
            type: 'scatter',
            mode: 'lines',
            marker: {
                color: '#29aae2'
            }
        };

        const data: Partial<PlotData>[] = [
            plotData
        ];

        const plotConfig: Partial<Config> = {
            displayModeBar: false,
            responsive: true
        };

        const plotLayout: Partial<Layout> = this.createPlotLayout();

        const plotSettings: PlotSettings = {
            data: data,
            config: plotConfig,
            layout: plotLayout
        };

        return plotSettings;
    }

    public createPlotLayout(): Partial<Layout> {

        return {
            margin: {
                l: 50,
                r: 50,
                b: 20,
                t: 20
            },
            autosize: true,
            xaxis: {
                range: [this.range[0], this.range[1]],
                rangeslider: {}
            },
            yaxis: {
                range: [this.axisConfiguration.min, this.axisConfiguration.max],
                dtick: this.axisConfiguration.interval,
                tickformat: `.${this.axisConfiguration.fractionalDigits}f`,
                type: this.axisConfiguration.scale,
            }
        };

    }
}
