import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable()
export class FeatureGuard implements CanActivate {

    constructor() { }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        const url = state.url;

        if (url.indexOf('/dashboard') > -1) {
            return environment.features.dashboard.isEnabled;
        }

        if (url.indexOf('/nodes') > -1) {
            return environment.features.nodes.isEnabled;
        }

        if (url.indexOf('/buildings') > -1) {
            return environment.features.floorPlan.isEnabled;
        }

        return true;
    }
}
