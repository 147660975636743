import { Config, Layout } from 'plotly.js';

import { AxisConfiguration } from '../models/axis-configuration.model';
import { ContourPlotDataValues } from '../models/contour-plot-data-values.model';
import { MeasurementType } from '../models/measurement-type.model';
import { PlotDatapointValues } from '../models/plot-datapoint-values.model';
import { PlotDimensions } from '../models/plot-dimensions.model';
import { PlotData, PlotSettings } from '../models/plot.model';

export function createFloorPlanPlotSettings(
  datapointValues: PlotDatapointValues,
  contourPlotDataValues: ContourPlotDataValues,
  measurements: number[],
  axisConfiguration: AxisConfiguration,
  plotDimensions: PlotDimensions,
  type: MeasurementType | 'Node'
): PlotSettings {
  return new FloorPlanPlotSettingsFactory(
    datapointValues,
    contourPlotDataValues,
    measurements,
    axisConfiguration,
    plotDimensions,
    type).create();
}

class FloorPlanPlotSettingsFactory {

  constructor(
    private readonly datapointValues: PlotDatapointValues,
    private readonly contourPlotDataValues: ContourPlotDataValues,
    private readonly measurements: number[],
    private readonly axisConfigurations: AxisConfiguration,
    private readonly plotDimensions: PlotDimensions,
    private readonly type: MeasurementType | 'Node') {
  }

  public create(): PlotSettings {

    for (let i = 0; i < this.measurements.length; i++) {
      const indexOfXCoordinates = this.contourPlotDataValues.x.indexOf(this.datapointValues.xCoordinates[i]);
      const indexOfYCoordinates = this.contourPlotDataValues.y.indexOf(this.datapointValues.yCoordinates[i]);
      this.contourPlotDataValues.z[indexOfYCoordinates][indexOfXCoordinates] = this.measurements[i];
    }

    const contourPlotData: Partial<PlotData> = {
      z: this.contourPlotDataValues.z,
      x: this.contourPlotDataValues.x,
      y: this.contourPlotDataValues.y,
      type: 'contour',
      hoverinfo: 'skip',
      connectgaps: true,
      showscale: true,
      colorbar: {
        x: 0.99,
        len: 0.5
      },
    };

    if (this.axisConfigurations) {
      contourPlotData.contours = {
        start: this.axisConfigurations.min,
        end: this.axisConfigurations.max,
        size: this.axisConfigurations.interval
      };
    }

    let data: Partial<PlotData>[];

    if (this.type === 'Node') {
      const scatterPlotData: Partial<PlotData> = this.getScatterPlotData(
        this.datapointValues.xCoordinates,
        this.datapointValues.yCoordinates,
        this.datapointValues.zCoordinates,
        'black',
        'Node',
        this.measurements,
        false
      );
      data = [
        contourPlotData,
        scatterPlotData
      ];
    } else {

      const coordinatesWithMeasurements: [number[], number[]] = [[], []];
      const coordinatesWithoutMeasurements: [number[], number[]] = [[], []];

      for (let i = 0; i < this.measurements.length; i++) {
        if (this.measurements[i] === null) {
          coordinatesWithoutMeasurements[0].push(this.datapointValues.xCoordinates[i]);
          coordinatesWithoutMeasurements[1].push(this.datapointValues.yCoordinates[i]);
        } else {
          coordinatesWithMeasurements[0].push(this.datapointValues.xCoordinates[i]);
          coordinatesWithMeasurements[1].push(this.datapointValues.yCoordinates[i]);
        }
      }

      const scatterPlotDataWithMeasurements: Partial<PlotData> =
        this.getScatterPlotData(
          coordinatesWithMeasurements[0],
          coordinatesWithMeasurements[1],
          this.datapointValues.zCoordinates,
          'black',
          this.type,
          this.measurements.filter(x => x !== null),
          true);

      const scatterPlotDataWithoutMeasurements: Partial<PlotData> =
        this.getScatterPlotData(
          coordinatesWithoutMeasurements[0],
          coordinatesWithoutMeasurements[1],
          this.datapointValues.zCoordinates,
          'gray',
          this.type,
          this.measurements.filter(x => x === null),
          false);

      data = [
        contourPlotData,
        scatterPlotDataWithMeasurements,
        scatterPlotDataWithoutMeasurements
      ];
    }


    const plotConfig: Partial<Config> = {
      displayModeBar: false,
      scrollZoom: true
    };

    const plotLayout: Partial<Layout> = {
      xaxis: {
        visible: false,
      },
      yaxis: {
        scaleanchor: 'x',
        scaleratio: 1,
        visible: false,
      },
      width: this.plotDimensions.width,
      height: this.plotDimensions.height,
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0
      },
      dragmode: 'pan',
      hovermode: 'closest',
      images: [
        {
          source: 'assets/images/floorplan1.png',
          xref: 'x',
          yref: 'y',
          x: 0,
          y: 0,
          sizex: 36,
          sizey: 14.98,
          xanchor: 'left',
          yanchor: 'bottom',
          layer: 'above',
          opacity: 1,
        }
      ]
    };

    const plotSettings: PlotSettings = {
      data: data,
      config: plotConfig,
      layout: plotLayout
    };

    return plotSettings;
  }

  private getScatterPlotData(
    x: number[],
    y: number[],
    z: number[],
    markerColor: any,
    type: MeasurementType | 'Node',
    measurements: number[],
    hasData: boolean): Partial<PlotData> {

    return {
      x: x,
      y: y,
      mode: 'markers',
      type: 'scatter',
      name: '',
      showlegend: false,
      text: this.getTextLabel(type, z, measurements, hasData),
      marker: {
        color: markerColor
      }
    };
  }

  private getTextLabel(
    type: MeasurementType | 'Node',
    z: number[],
    measurements: number[],
    hasData?: boolean): string[] {

    const textLabel: string[] = [];

    if (type === 'Node') {
      for (let i = 0; i < z.length; i++) {
        const label = `z: ${z[i]}`;

        textLabel.push(label);
      }
    } else {
      for (let i = 0; i < measurements.length; i++) {
        let label = `z: ${z[i]}`;

        label +=
          (hasData && measurements[i] !== null) ?
            `<br>${type}: ${measurements[i].toFixed(2)}` :
            `<br>${type}: unavailable`;

        textLabel.push(label);
      }
    }



    return textLabel;
  }
}
