import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@methylium/authentication';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private readonly authenticationServer: AuthenticationService) { }

  ngOnInit() {
  }

  public logout(): void {
    this.authenticationServer.logout();
  }
}
