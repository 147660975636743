import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard, OidcCallbackHandlerComponent, OidcSilentRenewHandlerComponent } from '@methylium/authentication';

import { DashboardViewComponent } from './views/dashboard/dashboard.view';
import { NodeDetailsViewComponent } from './views/node-details/node-details.view';
import { DefaultViewComponent } from './views/default/default.view';
import { NodesViewComponent } from './views/nodes/nodes.view';
import { BuildingsViewComponent } from './views/buildings/buildings.view';
import { FloorsViewComponent } from './views/floors/floors.view';

import { FeatureGuard } from './feature-guard';
import { additionalAppRoutes } from '../environments/environment';

const routes: Routes = [
  {
    path: 'callback',
    component: OidcCallbackHandlerComponent
  },
  {
    path: 'silent-renew',
    component: OidcSilentRenewHandlerComponent
  },
  {
    path: '',
    component: DefaultViewComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      ...additionalAppRoutes,
      {
        path: '',
        redirectTo: 'nodes',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardViewComponent,
        canActivate: [FeatureGuard]
      },
      {
        path: 'buildings/:building',
        component: BuildingsViewComponent,
        canActivate: [FeatureGuard],
        children: [
          {
            path: 'floors/:floor',
            component: FloorsViewComponent
          }
        ]
      },
      {
        path: 'nodes',
        component: NodesViewComponent,
        canActivate: [FeatureGuard]
      },
      {
        path: 'nodes/:id',
        component: NodeDetailsViewComponent,
        canActivate: [FeatureGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false // true for debugging purposes only
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
