import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Node = SmartBuildingApiModels.Node;

type Keys = [string, number];

export class NodesDictionary {
    private map = new Map<string, Node[]>();

    private key(p: Keys) {
        return `${p[0]}-${p[1]}`;
    }

    public set(p: Keys, value: Node[]) {
        const key = this.key(p);
        this.map.set(key, value);
    }

    public get(p: Keys) {
        const key = this.key(p);
        return this.map.get(key);
    }
}
