import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.view.html',
  styleUrls: ['./default.view.scss']
})
export class DefaultViewComponent implements OnInit {

  public isMenuOpen$: Observable<boolean>;

  public onToggleStateMenuChanged(): void {
    const state: boolean = !this.menuService.getToggleState();
    this.menuService.setToggleState(state);
  }

  constructor(
    private readonly menuService: MenuService) {
    this.isMenuOpen$ = this.menuService.toggleMenuState$;
  }

  ngOnInit() {
  }
}
