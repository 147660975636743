import { Routes } from '@angular/router';

import { AxisConfigurations } from 'src/app/models/axis-configurations.model';
import { LocationNodeFilter } from 'src/app/models/location-node-filter';

export const environment = {
  production: true,
  googleAnalyticsKey: 'UA-132549762-8',
  smartbuilding: {
    api: 'https://smartbuilding-api.methylium.com'
  },
  building: 'PRIVA',
  oidc: {
    authority: 'https://smartbuilding-accounts.methylium.com',
    client_id: 'priva-web',
    redirect_uri: 'https://priva.nerian.ai/callback',
    silent_redirect_uri: 'https://priva.nerian.ai/silent-renew',
    response_type: 'id_token token',
    scope: 'openid smartbuilding-api',
    post_logout_redirect_uri: 'https://priva.nerian.ai',
    automaticSilentRenew: true
  },
  features: {
    dashboard: {
      isEnabled: false
    },
    nodes: {
      isEnabled: true
    },
    floorPlan: {
      isEnabled: true
    }
  }
};

export const nodeFilter: LocationNodeFilter = {
  building: null,
  floors: [1]
};

export const additionalAppRoutes: Routes = [];

export const axisConfigurations: AxisConfigurations = {
  temperature: {
    min: 18,
    max: 28,
    interval: 1,
    scale: 'linear',
    fractionalDigits: 1
  },
  humidity: {
    min: 0,
    max: 100,
    interval: 10,
    scale: 'linear',
    fractionalDigits: 0
  },
  lightIntensity: {
    min: -1,
    max: 4,
    interval: 1,
    scale: 'log',
    fractionalDigits: 0
  },
  movement: {
    min: 0,
    max: 100,
    interval: 10,
    scale: 'linear',
    fractionalDigits: 0
  },
  CO2: {
    min: 0,
    max: 3000,
    interval: 250,
    scale: 'linear',
    fractionalDigits: -1
  },
  noiseLevel: {
    min: 0,
    max: 100,
    interval: 10,
    scale: 'linear',
    fractionalDigits: 0
  },
};
