import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BuildingsService } from '../../services/buildings.service';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.view.html',
  styleUrls: ['./buildings.view.scss']
})
export class BuildingsViewComponent implements OnInit, OnDestroy {

  public floors$: Observable<number[]>;
  private floorsSubscription: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly buildingsService: BuildingsService) {
  }

  public ngOnInit(): void {

    const building: string = this.route.snapshot.paramMap.get('building');

    this.floors$ = this.buildingsService.getFloors(building);

    this.floorsSubscription = this.floors$
      .pipe(filter((floors: number[]) => floors.length > 0))
      .subscribe((floors: number[]) =>
        this.onFloorSelected(floors[0]));
  }

  public onFloorSelected(floor: number): void {
    this.router.navigate([`floors/${floor}`], { relativeTo: this.route });
  }

  public ngOnDestroy(): void {
    this.floorsSubscription.unsubscribe();
  }
}
