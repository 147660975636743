import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BuildingsStore } from '../stores/buildings.store';

@Injectable()
export class BuildingsService {

    constructor(
        private readonly buildingsStore: BuildingsStore) {
    }

    public getBuildings(): Observable<string[]> {
        return this.buildingsStore.getBuildings();
    }

    public getFloors(building: string): Observable<number[]> {
        return this.buildingsStore.getFloors(building);
    }
}
