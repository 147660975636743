type Key = string;

export class BuildingsDictionary {
    private map = new Map<string, number[]>();

    private key(p: Key) {
        return p;
    }

    public set(p: Key, value: number[]) {
        const key = this.key(p);
        this.map.set(key, value);
    }

    public get(p: Key) {
        const key = this.key(p);
        return this.map.get(key);
    }
}
