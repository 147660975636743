import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.view.html',
  styleUrls: ['./dashboard.view.scss']
})
export class DashboardViewComponent implements OnInit {

  constructor() {
  }

  public ngOnInit(): void {
  }
}
