import { DateRange } from '../models/date-range.model';
import * as moment from 'moment';

export function subtractDateRange(currentDate: Date, range: DateRange): Date {

    const date = new Date(currentDate);

    switch (range) {
        case DateRange.Week:
            date.setDate(date.getDate() - 7);
            break;
        case DateRange.Month:
            date.setMonth(date.getMonth() - 1);
            break;
        case DateRange.Year:
            date.setFullYear(date.getFullYear() - 1);
            break;
        case DateRange.Day:
        default:
            date.setDate(date.getDate() - 1);
    }

    return date;
}

export function getToDate(currentDate: Date, range: DateRange): Date {

    const date = moment(currentDate);

    switch (range) {
        case DateRange.Week:
            return date.endOf('week').toDate();
        case DateRange.Month:
            return date.endOf('month').toDate();
        case DateRange.Year:
            return date.endOf('year').toDate();
        case DateRange.Day:
        default:
            return date.endOf('day').toDate();
    }
}

export function getFromDate(currentDate: Date, range: DateRange): Date {

    const date = moment(currentDate);

    switch (range) {
        case DateRange.Week:
            return date.startOf('week').toDate();
        case DateRange.Month:
            return date.startOf('month').toDate();
        case DateRange.Year:
            return date.startOf('year').toDate();
        case DateRange.Day:
        default:
            return date.startOf('day').toDate();
    }
}

export function convertDateToUTC(date: Date): Date {
    return new Date(date.toISOString());
}
