import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class DateSelectorComponent implements OnInit, OnChanges {

  @Output()
  public readonly dateChange: EventEmitter<Date>;

  @Input()
  public selectedDate: Date;

  constructor() {
    this.dateChange = new EventEmitter();
  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
  }

  public onDateChanged(date: Date): void {
    this.dateChange.emit(date);
  }
}
