import { Component } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';

import { environment } from '../environments/environment';

import { filter, map } from 'rxjs/operators';

declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private readonly router: Router) {

    ga('create', environment.googleAnalyticsKey, 'auto');

    this.trackPageViews();
  }

  private trackPageViews(): void {
    this.router.events.pipe(
      filter((e: Event) => e instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects))
      .subscribe((url: string) => {
        ga('set', 'page', url);
        ga('send', 'pageview');
      });
  }
}
