import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { NodesService } from 'src/app/services/nodes.service';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Node = SmartBuildingApiModels.Node;

@Component({
  selector: 'app-nodes',
  templateUrl: './nodes.view.html',
  styleUrls: ['./nodes.view.scss']
})
export class NodesViewComponent implements OnInit {

  public nodes$: Observable<Node[]>;

  constructor(private readonly nodesService: NodesService) { }

  ngOnInit() {
    this.nodes$ = this.nodesService.getNodes();
  }
}
