import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { MeasurementsStore } from '../stores/measurements.store';
import { MeasurementsFilter } from '../models/measurements-filter.model';
import { convertDateToUTC } from '../helpers/date.helper';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Temperature = SmartBuildingApiModels.Temperature;
import Humidity = SmartBuildingApiModels.Humidity;
import CO2 = SmartBuildingApiModels.CO2;
import LightIntensity = SmartBuildingApiModels.LightIntensity;
import Movement = SmartBuildingApiModels.Movement;
import NoiseLevel = SmartBuildingApiModels.NoiseLevel;
import { MeasurementType } from '../models/measurement-type.model';
import { Measurement } from '../models/measurement.model';
import { ODataOptions } from '../models/odata.model';

@Injectable()
export class MeasurementsService {

  constructor(private readonly measurementsStore: MeasurementsStore) {
  }

  public getMeasurements(
    measurementType: MeasurementType | 'Node',
    odataOptions: ODataOptions): Observable<Measurement[]> {


    switch (measurementType) {
      case MeasurementType.Temperature: {
        return this.getTemperatureMeasurements(odataOptions);
      }
      case MeasurementType.LightIntensity: {
        return this.getLightIntensityMeasurements(odataOptions);
      }
      case MeasurementType.Movement: {
        return this.getMovementMeasurements(odataOptions);
      }
      case MeasurementType.CO2: {
        return this.getCo2Measurements(odataOptions);
      }
      case MeasurementType.Humidity: {
        return this.getHumidityMeasurements(odataOptions);
      }
      case MeasurementType.NoiseLevel: {
        return this.getNoiseLevelMeasurements(odataOptions);
      }
      case 'Node': {
        return of([]);
      }
    }
  }

  public getTemperatureMeasurements(odataOptions: ODataOptions): Observable<Temperature[]> {
    return this.measurementsStore.getTemperatureMeasurements(odataOptions);
  }

  public getHumidityMeasurements(odataOptions: ODataOptions): Observable<Humidity[]> {
    return this.measurementsStore.getHumidityMeasurements(odataOptions);
  }

  public getCo2Measurements(odataOptions: ODataOptions): Observable<CO2[]> {
    return this.measurementsStore.getCo2Measurements(odataOptions);
  }

  public getLightIntensityMeasurements(odataOptions: ODataOptions): Observable<LightIntensity[]> {
    return this.measurementsStore.getLightIntensityMeasurements(odataOptions);
  }

  public getMovementMeasurements(odataOptions: ODataOptions): Observable<Movement[]> {
    return this.measurementsStore.getMovementMeasurements(odataOptions);
  }

  public getNoiseLevelMeasurements(odataOptions: ODataOptions): Observable<NoiseLevel[]> {
    return this.measurementsStore.getNoiseLevelMeasurements(odataOptions);
  }

  public getFilter(
    to: Date,
    from: Date,
    floor: number,
    nodeId?: number,
    building?: string): MeasurementsFilter {

    const filter: MeasurementsFilter = {
      nodeId: nodeId,
      building: building,
      floor: floor,
      from: convertDateToUTC(from),
      to: convertDateToUTC(to)
    };

    return filter;
  }
}
