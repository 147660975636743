import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';

import { PlotSettings } from '../../models/plot.model';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Node = SmartBuildingApiModels.Node;

@Component({
  selector: 'app-floor-plan-chart',
  templateUrl: './floor-plan-chart.component.html',
  styleUrls: ['./floor-plan-chart.component.scss']
})
export class FloorPlanChartComponent implements OnInit, OnChanges {

  @Input()
  public nodes: Node[];

  @Input()
  public floorPlanPlotSettings: PlotSettings;

  @Output()
  public navigateToNode: EventEmitter<Node>;

  constructor() {
    this.navigateToNode = new EventEmitter();
  }

  public onGraphClick(params: any): void {
    if (params.event && params.event.type === 'mousedown') {

      const node: Node = this.nodes
        .find((n: Node) => n.x === params.points[0].x && n.y === params.points[0].y);

      if (node) {
        this.navigateToNode.emit(node);
      }
    }
  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
  }
}
