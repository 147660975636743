import { Component } from '@angular/core';

import { IFloatingFilter, SerializedNumberFilter } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';

import { NumberDropdownFloatingFilterParams } from 'src/app/models/number-dropdown-floating-filter-params.model';
import { NumberDropdownFloatingFilterChange } from 'src/app/models/number-dropdown-floating-filter-change.model';

@Component({
  selector: 'app-number-dropdown-filter',
  templateUrl: './number-dropdown-filter.component.html',
})
export class NumberDropdownFilterComponent implements
  IFloatingFilter<SerializedNumberFilter, NumberDropdownFloatingFilterChange, NumberDropdownFloatingFilterParams>,
  AgFrameworkComponent<NumberDropdownFloatingFilterParams> {
  public params: NumberDropdownFloatingFilterParams;
  public currentValue: number;

  agInit(params: NumberDropdownFloatingFilterParams): void {
    this.params = params;
  }

  valueChanged(value: string): void {
    this.currentValue = value === '' ? null : +value;
    this.params.onFloatingFilterChanged({ model: this.buildModel() });
  }

  onParentModelChanged(): void {
  }

  private buildModel(): SerializedNumberFilter {
    if (this.currentValue === null) {
      return null;
    } else if (this.currentValue === Number(this.currentValue)) {
      return {
        filterType: 'number',
        type: 'equals',
        filter: this.currentValue,
        filterTo: null
      };
    }
  }
}
