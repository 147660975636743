import { UserManagerSettings } from 'oidc-client';
import { environment } from '../../environments/environment';

export abstract class OIDCHelper {

    public static create(): UserManagerSettings {
        return {
            authority: environment.oidc.authority,
            client_id: environment.oidc.client_id,
            redirect_uri: environment.oidc.redirect_uri,
            silent_redirect_uri: environment.oidc.silent_redirect_uri,
            response_type: environment.oidc.response_type,
            scope: environment.oidc.scope,
            post_logout_redirect_uri: environment.oidc.post_logout_redirect_uri,
            automaticSilentRenew: environment.oidc.automaticSilentRenew
        };
    }
}
