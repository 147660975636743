import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MenuService {

    private _toggleMenuState$ = new BehaviorSubject<boolean>(false);
    public toggleMenuState$ = this._toggleMenuState$.asObservable();

    public setToggleState(state: boolean): void {
        this._toggleMenuState$.next(state);
    }

    public getToggleState(): boolean {
        return this._toggleMenuState$.getValue();
    }

    constructor() {
    }
}
