import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { Datum } from 'plotly.js';

import { PlotSettings } from '../../models/plot.model';
import { MeasurementType } from '../../models/measurement-type.model';
import { createMeasurementsPlotLayout } from '../../factories/measurements-plot-settings.factory';
import { getAxisConfig } from '../../helpers/chart.helper';

@Component({
  selector: 'app-measurements-chart',
  templateUrl: './measurements-chart.component.html',
  styleUrls: ['./measurements-chart.component.scss']
})
export class MeasurementsChartComponent implements OnChanges {

  @Input()
  public measurementsChartType: MeasurementType;

  @Input()
  public title: string;

  @Input()
  public subtitle: string;

  @Input()
  public plotSettings: PlotSettings;

  @Input()
  public isDataLoading: boolean;

  @Input()
  public rangeSlider: Datum[];

  @Output()
  public rangeSliderChange: EventEmitter<Datum[]>;

  constructor() {
    this.rangeSliderChange = new EventEmitter();
  }

  public get hasData(): boolean {
    return (this.plotSettings != null && this.plotSettings.data != null && this.plotSettings.data[0].x.length > 0);
  }

  public onRelayout(params: any): void {

    if (params['xaxis.range']) {
      this.rangeSliderChange.emit(params['xaxis.range']);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes.rangeSlider &&
      changes.rangeSlider.currentValue) {
      this.plotSettings.layout =
        createMeasurementsPlotLayout(changes.rangeSlider.currentValue, getAxisConfig(this.measurementsChartType));
    }
  }
}
