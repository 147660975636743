import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '../services/api.service';
import { ODataOptions } from '../models/odata.model';

import { MeasurementsEventsService } from '../services/measurements-events.service';
import { TemperatureMeasurementLoadStarted } from '../models/events/temperature-measurement-load-started.model';
import { HumidityMeasurementLoadStarted } from '../models/events/humidity-measurement-load-started.model';
import { LightIntensityMeasurementLoadStarted } from '../models/events/light-intensity-measurement-load-started.model';
import { MovementMeasurementLoadStarted } from '../models/events/movement-measurement-load-started.model';
import { NoiseLevelMeasurementLoadStarted } from '../models/events/noise-level-measurement-load-started.model';
import { CO2MeasurementLoadStarted } from '../models/events/co2-measurement-load-started.model';
import { TemperatureMeasurementLoadCompleted } from '../models/events/temperature-measurement-load-completed.model';
import { NoiseLevelMeasurementLoadCompleted } from '../models/events/noise-level-measurement-load-completed.model';
import { MovementMeasurementLoadCompleted } from '../models/events/movement-measurement-load-completed.model';
import { LightIntensityMeasurementLoadCompleted } from '../models/events/light-intensity-measurement-load-completed.model';
import { CO2MeasurementLoadCompleted } from '../models/events/co2-measurement-load-completed.model';
import { HumidityMeasurementLoadCompleted } from '../models/events/humidity-measurement-load-completed.model';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Temperature = SmartBuildingApiModels.Temperature;
import Humidity = SmartBuildingApiModels.Humidity;
import CO2 = SmartBuildingApiModels.CO2;
import LightIntensity = SmartBuildingApiModels.LightIntensity;
import Movement = SmartBuildingApiModels.Movement;
import NoiseLevel = SmartBuildingApiModels.NoiseLevel;

@Injectable()
export class MeasurementsStore {

    constructor(
        private readonly apiService: ApiService,
        private readonly measurementsEventsService: MeasurementsEventsService
    ) { }

    public getTemperatureMeasurements(odataOptions: ODataOptions): Observable<Temperature[]> {
        this.measurementsEventsService.publish(new TemperatureMeasurementLoadStarted());

        return from(this.apiService.client.getTemperatureMeasurements(odataOptions)).pipe(
                tap((_ => this.measurementsEventsService.publish(new TemperatureMeasurementLoadCompleted()))));
    }

    public getHumidityMeasurements(odataOptions: ODataOptions): Observable<Humidity[]> {
        this.measurementsEventsService.publish(new HumidityMeasurementLoadStarted());

        return from(this.apiService.client.getHumidityMeasurements(odataOptions)).pipe(
                tap(_ => this.measurementsEventsService.publish(new HumidityMeasurementLoadCompleted())));
    }

    public getCo2Measurements(odataOptions: ODataOptions): Observable<CO2[]> {
        this.measurementsEventsService.publish(new CO2MeasurementLoadStarted());

        return from(this.apiService.client.getCO2Measurements(odataOptions)).pipe(
                tap(_ => this.measurementsEventsService.publish(new CO2MeasurementLoadCompleted())));

    }

    public getLightIntensityMeasurements(odataOptions: ODataOptions): Observable<LightIntensity[]> {
        this.measurementsEventsService.publish(new LightIntensityMeasurementLoadStarted());

        return from(this.apiService.client.getLightIntensityMeasurements(odataOptions)).pipe(
                tap(_ => this.measurementsEventsService.publish(new LightIntensityMeasurementLoadCompleted())));
    }

    public getMovementMeasurements(odataOptions: ODataOptions): Observable<Movement[]> {
        this.measurementsEventsService.publish(new MovementMeasurementLoadStarted());

        return from(this.apiService.client.getMovementMeasurements(odataOptions)).pipe(
                tap(_ => this.measurementsEventsService.publish(new MovementMeasurementLoadCompleted())));
    }

    public getNoiseLevelMeasurements(odataOptions: ODataOptions): Observable<NoiseLevel[]> {
        this.measurementsEventsService.publish(new NoiseLevelMeasurementLoadStarted());

        return from(this.apiService.client.getNoiseLevelMeasurements(odataOptions)).pipe(
                tap(_ => this.measurementsEventsService.publish(new NoiseLevelMeasurementLoadCompleted)));
    }
}
