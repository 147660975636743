import { Component, OnInit, Input } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';

import { RouterLinkRendererComponent } from '../router-link-renderer/router-link-renderer.component';
import { TextDropdownFilterComponent } from '../text-dropdown-filter/text-dropdown-filter.component';
import { NumberDropdownFilterComponent } from '../number-dropdown-filter/number-dropdown-filter.component';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Node = SmartBuildingApiModels.Node;

@Component({
  selector: 'app-nodes-list',
  templateUrl: './nodes-list.component.html',
  styleUrls: ['./nodes-list.component.scss']
})
export class NodesListComponent implements OnInit {

  @Input()
  public nodes: Node[];

  constructor() {
  }

  public gridOptions: GridOptions = {
    enableColResize: true,
    onGridSizeChanged: () => {
      this.gridOptions.api.sizeColumnsToFit();
    },
    onRowDataChanged: () => {

      const colsDefs = this.getColumnDefs(
        this.nodes.map(n => n.building)
          .filter((element, position, array) => array.indexOf(element) === position),
        this.nodes.map(n => n.floor)
          .filter((element, position, array) => array.indexOf(element) === position).sort(),
        this.nodes.map(n => +n.type)
          .filter((element, position, array) => array.indexOf(element) === position).sort((a, b) => a - b)
      );

      this.gridOptions.api.setColumnDefs(colsDefs);
    }
  };

  ngOnInit() {
  }

  public onFirstDataRendered(params: any): void {
    params.api.sizeColumnsToFit();
  }

  private getColumnDefs(
    buildings: string[],
    floors: number[],
    types: number[]): ColDef[] {

    return [
      {
        headerName: 'Name',
        field: 'name',
        floatingFilterComponentParams: {
          suppressFilterButton: true
        },
        cellRendererFramework: RouterLinkRendererComponent,
        cellRendererParams: {
          inRouterLink: '/nodes',
        }
      },
      {
        headerName: 'Building Name',
        field: 'building',
        filter: 'agTextColumnFilter',
        floatingFilterComponentFramework: TextDropdownFilterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          uniqueValues: buildings
        }
      },
      {
        headerName: 'Floor',
        field: 'floor',
        filter: 'agNumberColumnFilter',
        floatingFilterComponentFramework: NumberDropdownFilterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          uniqueValues: floors
        }
      },
      {
        headerName: 'Type',
        field: 'type',
        filter: 'agNumberColumnFilter',
        floatingFilterComponentFramework: NumberDropdownFilterComponent,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          uniqueValues: types
        }
      },
      {
        headerName: 'X',
        field: 'x',
        suppressFilter: true
      },
      {
        headerName: 'Y',
        field: 'y',
        suppressFilter: true
      },
      {
        headerName: 'Z',
        field: 'z',
        suppressFilter: true
      }
    ];
  }
}
