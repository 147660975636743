import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AuthenticationModule, AuthenticationService } from '@methylium/authentication';

import { AgGridModule } from 'ag-grid-angular';
import { PlotlyModule } from 'angular-plotly.js';
import * as moment from 'moment';

import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/logo/logo.component';
import { NodesListComponent } from './components/nodes-list/nodes-list.component';
import { NumberDropdownFilterComponent } from './components/number-dropdown-filter/number-dropdown-filter.component';
import { LogoutComponent } from './components/profile/logout/logout.component';
import { RouterLinkRendererComponent } from './components/router-link-renderer/router-link-renderer.component';
import { TextDropdownFilterComponent } from './components/text-dropdown-filter/text-dropdown-filter.component';
import { DashboardViewComponent } from './views/dashboard/dashboard.view';
import { DefaultViewComponent } from './views/default/default.view';
import { NodeDetailsViewComponent } from './views/node-details/node-details.view';
import { NodesViewComponent } from './views/nodes/nodes.view';
import { MeasurementsChartComponent } from './components/measurements-chart/measurements-chart.component';
import { BuildingsViewComponent } from './views/buildings/buildings.view';
import { FloorsViewComponent } from './views/floors/floors.view';
import { BuildingSelectorComponent } from './components/building-selector/building-selector.component';
import { FloorSelectorComponent } from './components/floor-selector/floor-selector.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';

import { OIDCHelper } from './helpers/oidc.helper';
import { ApiService } from './services/api.service';
import { MeasurementsService } from './services/measurements.service';
import { NodesService } from './services/nodes.service';
import { MeasurementsStore } from './stores/measurements.store';
import { NodesStore } from './stores/nodes.store';
import { MeasurementsChartService } from './services/measurements-chart.service';
import { FloorPlanChartComponent } from './components/floor-plan-chart/floor-plan-chart.component';
import { BuildingsStore } from './stores/buildings.store';
import { BuildingsService } from './services/buildings.service';
import { DateRangeSelectorComponent } from './components/date-range-selector/date-range-selector.component';
import { FloorPlanChartService } from './services/floor-plan-chart.service';
import { HamburgerMenuComponent } from './components/hamburger-menu/hamburger-menu.component';
import { MenuService } from './services/menu.service';
import { MeasurementTypeSelectorComponent } from './components/measurement-type-selector/measurement-type-selector.component';
import { FeatureGuard } from './feature-guard';

@NgModule({
  declarations: [
    AppComponent,
    DefaultViewComponent,
    DashboardViewComponent,
    NodesListComponent,
    NodeDetailsViewComponent,
    NumberDropdownFilterComponent,
    TextDropdownFilterComponent,
    RouterLinkRendererComponent,
    HeaderComponent,
    LogoComponent,
    LogoutComponent,
    NodesViewComponent,
    NodesViewComponent,
    MeasurementsChartComponent,
    BuildingsViewComponent,
    FloorsViewComponent,
    FloorPlanChartComponent,
    BuildingSelectorComponent,
    FloorSelectorComponent,
    DateSelectorComponent,
    DateRangeSelectorComponent,
    HamburgerMenuComponent,
    MeasurementTypeSelectorComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    PlotlyModule,
    FormsModule,
    AppRoutingModule,
    AuthenticationModule.forRoot(OIDCHelper.create()),
    AgGridModule.withComponents([
      NumberDropdownFilterComponent,
      TextDropdownFilterComponent,
      RouterLinkRendererComponent
    ]),
    NgbDatepickerModule,
    NgbDropdownModule
  ],
  providers: [
    AuthenticationService,
    ApiService,
    NodesStore,
    NodesService,
    MeasurementsStore,
    MeasurementsService,
    MeasurementsChartService,
    BuildingsStore,
    BuildingsService,
    FloorPlanChartService,
    MenuService,
    FeatureGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
