import { Component, OnInit, } from '@angular/core';

import { MenuService } from '../../services/menu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss']
})
export class HamburgerMenuComponent implements OnInit {

  public showMenu$: Observable<boolean>;

  constructor(
    private readonly menuService: MenuService) {
      this.showMenu$ = this.menuService.toggleMenuState$;
  }

  public ngOnInit(): void {
  }

  public onToggleState(): void {
    const state: boolean = !this.menuService.getToggleState();
    this.menuService.setToggleState(state);
  }
}
