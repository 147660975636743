import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NodesStore } from '../stores/nodes.store';

import { SmartBuildingApiModels } from '@smartbuilding/sdk';
import Node = SmartBuildingApiModels.Node;

@Injectable()
export class NodesService {

  constructor(private readonly nodesStore: NodesStore) {
  }

  public getNodes(building?: string, floor?: number): Observable<Node[]> {
    return this.nodesStore.getNodes(building, floor);
  }

  public getNodeById(id: number): Observable<Node> {
    return this.nodesStore.getNodeById(id);
  }
}
