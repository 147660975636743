import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MeasurementTypeSelectorConfig } from '../../models/measurement-type-selector-config.model';
import { MeasurementType } from '../../models/measurement-type.model';

@Component({
  selector: 'app-measurement-type-selector',
  templateUrl: './measurement-type-selector.component.html',
  styleUrls: ['./measurement-type-selector.component.scss']
})
export class MeasurementTypeSelectorComponent implements OnInit {

  @Output()
  public measurementTypeChange: EventEmitter<MeasurementType | 'Node'>;

  public measurementTypeConfig: MeasurementTypeSelectorConfig[];

  public selectedMeasurementType: MeasurementType | 'Node' = 'Node';

  constructor() {
    this.measurementTypeChange = new EventEmitter();

    this.measurementTypeConfig = [
      {
        value: 'Node',
        icon: 'fas fa-circle'
      },
      {
        value: MeasurementType.Temperature,
        icon: 'fas fa-thermometer-empty'
      },
      {
        value: MeasurementType.Humidity,
        icon: 'fas fa-tint'
      },
      {
        value: MeasurementType.LightIntensity,
        icon: 'fas fa-lightbulb'
      },
      {
        value: MeasurementType.CO2,
        icon: 'fas fa-cloud'
      },
      {
        value: MeasurementType.Movement,
        icon: 'fas fa-users'
      },
      {
        value: MeasurementType.NoiseLevel,
        icon: 'fas fa-volume-up'
      }
    ];
  }

  ngOnInit(): void {
  }

  public onMeasurementTypeSelected(measurementType: MeasurementType | 'Node'): void {
    this.measurementTypeChange.emit(measurementType);
    this.selectedMeasurementType = measurementType;
  }
}
